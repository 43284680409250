<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label  class="mr-2 text-white">Sık Sorulan Sorular</label>
      </template>
      <div v-html="getFaqItems"></div>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getFaqItems"]),
  },
  created() {
    if (this.$store.getters.checkToken.isFederation) {
      this.$store.dispatch("initFaq", "federationFaq");
    } else {
      this.$store.dispatch("initFaq", "personelFaq");
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}

</style>